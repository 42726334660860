<template>
  <div style="height: 100%">
    <div class="script">
      <div class="script-top" v-show="!state">
        <div class="st-selectChart" @click="show = true">
          {{ info.title }} <i class="font_family icon-31xiala"></i>
        </div>
        <div class="st-copy" @click="onCopy">
          一键复制文案 <i class="font_family icon-fuzhipeizhi"></i>
        </div>
      </div>
      <!-- 内容区域 -->
      <!-- 上下滚动 -->
      <scroll-page ref="extension" :state="state" :config="config"
        @touchstart="stop" @over="over" :content="content"></scroll-page>
      <!-- 上下平移 -->
      <!-- <up-down
        v-if="config.checkedTurn == '2'"
        ref="extension"
        :state="state"
        :config="config"
        @over="over"
        :content="content"
      ></up-down> -->
      <!-- 左右平移 -->
      <!-- 占位用的 -->
      <!-- <div class="script-options-zhanwe" v-show="!state"></div> -->
      <div class="script-options" @click.stop v-show="!state">
        <div class="so-top">
          <van-button size="small" class="options-btn"
            :icon="stateFlag ? 'play-circle-o' : 'pause-circle-o'"
            type="primary" @click.stop="start">{{ stateFlag ? '开始播放' : '继续播放' }}
          </van-button>
          <van-slider class="options-slider" v-model="config.val"
            bar-height="9.4vw" :min="0" :max="100" inactive-color="#FAEBE5">
            <template #button>
              <div class="custom-button">{{ config.text }}</div>
            </template>
          </van-slider>
          <van-button size="small" class="options-btn-set"
            @click.stop="setOptions">设置</van-button>
        </div>
        <div class="so-tips">点击"开始播放" 5秒后开始播放</div>
      </div>
    </div>
    <!-- 文案选择 -->
    <van-popup v-model:show="show" round position="bottom">
      <van-picker ref="picker" title="选择文案" value-key="title" :columns="actions"
        @cancel="clearPicker" @confirm="onSelect" />
    </van-popup>
    <!-- 设置 -->
    <van-popup v-model:show="set" position="bottom" overlay-class="setOverlay">
      <div class="setBox">
        <div class="setBoxRow">
          <div class="setBoxRow-label">字号</div>
          <div class="setBoxRow-options setBoxRow-font">
            <div class="sbr-font-btn" @click="calculation">A-</div>
            <div class="sbr-font-num">{{ config.fontSize }}</div>
            <div class="sbr-font-btn" @click="calculation('add')">A+</div>
          </div>
        </div>
        <div class="setBoxRow">
          <div class="setBoxRow-label">间距</div>
          <div class="setBoxRow-options" style="justify-content: space-between">
            <van-radio-group v-model="config.checkedLine">
              <van-radio v-for="item in line" :key="item.id" :name="item.id"
                @click="setLine(item)">
                <template #icon="props">
                  <div class="sbr-spacing" :class="{
                      'setBoxRow-border': config.checkedLine == item.id
                    }" :checked="props.checked">
                    <i :class="['font_family', item.icon]"></i>
                  </div>
                </template>
              </van-radio>
            </van-radio-group>
          </div>
        </div>
        <div class="setBoxRow">
          <div class="setBoxRow-label">背景色</div>
          <div class="setBoxRow-options">
            <van-radio-group v-model="config.checked">
              <van-radio v-for="item in color" :key="item.id" :name="item.id"
                @click="setBgColor(item)">
                <template #icon="props">
                  <div :class="{
                      'setBoxRow-bg': true,
                      'setBoxRow-border': config.checked == item.id
                    }" :style="{ background: item.bgColor }"
                    :checked="props.checked"></div>
                </template>
              </van-radio>
            </van-radio-group>
          </div>
        </div>
        <!--      <div class="setBoxRow">
          <div class="setBoxRow-label">背景图</div>
          <div class="setBoxRow-options">
            <van-radio-group v-model="config.bgChecked">
              <van-radio
                v-for="item in bgImg"
                :key="item.id"
                :name="item.id"
                @click="setBgImg(item)"
              >
                <template #icon="props">
                  <img
                    :src="item.img"
                    v-if="item.id != 0"
                    :class="{
                      'setBoxRow-bg': true,
                      'setBoxRow-border': config.bgChecked == item.id
                    }"
                    :checked="props.checked"
                    alt=""
                  />
                  <div
                    v-else
                    :class="{
                      'setBoxRow-bg': true,
                      'setBoxRow-border': config.bgChecked == item.id
                    }"
                    :style="{ background: '#f9f9f9' }"
                    :checked="props.checked"
                  ></div>
                </template>
              </van-radio>
            </van-radio-group>
          </div>
        </div> -->
        <!--    <div class="setBoxRow">
          <div class="setBoxRow-label">翻页</div>
          <div class="setBoxRow-options">
            <van-radio-group
              v-model="config.checkedTurn"
              style="justify-content: flex-start"
            >
              <van-radio
                v-for="item in turnPages"
                :key="item.id"
                :name="item.id"
              >
                <template #icon="props">
                  <div
                    class="sbr-spacing-turn"
                    :class="{
                      'setBoxRow-border': config.checkedTurn == item.id
                    }"
                    :checked="props.checked"
                  >
                    {{ item.name }}
                  </div>
                </template>
              </van-radio>
            </van-radio-group>
          </div>
        </div> -->
      </div>
    </van-popup>
  </div>
</template>

<script>
import scrollPage from './components/extensionScroll.vue'
import upDown from './components/extensionUp.vue'
import { getTaskContentList } from '@/api/script.js'
import { Toast } from 'vant'
export default {
  name: 'scriptDom',
  components: {
    scrollPage,
    upDown
  },
  data() {
    return {
      // 文案选择
      show: false,
      // 设置
      set: false,
      actions: [],
      info: {},
      text: '',
      content: '',
      // 控制开始按钮文案
      stateFlag: true,
      // 控制播放状态
      // state: true,
      state: false,
      config: {
        bgColor: '#f9f9f9',
        fzColor: 'black',
        fontSize: 23,
        lineHeight: 1.6,
        val: 50,
        text: '匀速',
        checked: '4',
        checkedLine: '2',
        bgChecked: 0,
        bgImg: ''
      },
      color: [
        {
          id: 1,
          bgColor: '#F3DDBA',
          fzColor: '#523120'
        },
        {
          id: 2,
          bgColor: '#CFE8CB',
          fzColor: '#1A2D19'
        },
        {
          id: 3,
          bgColor: '#FDE4DE',
          fzColor: '#452B2C'
        },
        {
          id: 4,
          bgColor: '#f9f9f9',
          fzColor: 'black'
        },
        {
          id: 5,
          bgColor: '#181818',
          fzColor: '#929292'
        }
      ],
      bgImg: [
        {
          id: 0,
          img: ''
        },
        {
          id: 1,
          img: require('./img/1.png')
        },
        {
          id: 2,
          img: require('./img/2.png')
        },
        {
          id: 3,
          img: require('./img/3.png')
        },
        {
          id: 4,
          img: require('./img/4.png')
        }
      ],
      line: [
        {
          id: 1,
          icon: 'icon-a-xingzhuangjiehe2',
          val: 1.2
        },
        {
          id: 2,
          icon: 'icon-a-1',
          val: 1.6
        },
        {
          id: 3,
          icon: 'icon-a-xingzhuangjiehe3',
          val: 2.4
        },
        {
          id: 4,
          icon: 'icon-a-xingzhuangjiehe4',
          val: 2.8
        }
      ],
      turnPages: [
        {
          id: 1,
          name: '上下滚动'
        },
        {
          id: 2,
          name: '上下平移'
        }
      ]
    }
  },

  watch: {
    'config.val': {
      handler: function (newVal) {
        if (newVal >= 75) {
          this.config.text = '快速'
        } else if (newVal <= 30) {
          this.config.text = '慢速'
          if (newVal <= 20) {
            this.config.val = 20
          }
        } else {
          this.config.text = '匀速'
        }
      }
    },
    config: {
      handler(newVal) {
        window.localStorage.setItem('config', JSON.stringify(newVal))
      },
      deep: true
    }
  },
  async beforeMount() {
    document.title = this.$route.query.appName || '推广书籍'
    let bookId = this.$route.query.id
    let res = await getTaskContentList({ bookId })
    this.info = res.list[0]
    this.content = this.info.content
    this.actions = res.list.map(item => {
      item.name = item.title
      return item
    })

    let config = JSON.parse(window.localStorage.getItem('config'))
    //这步是兼容老版本的行高设置，用户用一段时间后就可以删了这行代码
    if (config) {
      if (config.lineHeight > 3) {
        config.lineHeight = 1.6
      }
      if (!config.bgImg) {
        this.config.bgImg = this.bgImg[0].img
      }
      this.config = config
    } else {
      this.config.bgImg = this.bgImg[0].img
    }
  },
  beforeUnmount() {
    this.stop()
  },
  //使用文案返回我的推广，查看文案返回文案库
  beforeRouteLeave(to, form, next) {
    if (to.fullPath == '/extension') {
      return next()
    } else return next('/extension')
  },
  methods: {
    start() {
      this.state = true
      if (this.state) {
        // stateFlag == true 是开始播放， false是继续播放
        this.stateFlag = false
      }
    },
    stop() {
      if (this.state == true) {
        this.stateFlag = false
        this.state = false
      }
    },
    onSelect(item) {
      this.show = false
      this.state = false
      this.info = JSON.parse(JSON.stringify(item))
      this.content = this.info.content
      this.$refs.extension.reset()
      this.stateFlag = true
    },

    onCopy() {
      this.$copyText(this.info.content)
        .then(() => {
          Toast('已成功复制到剪切板')
        })
        .catch(() => {
          Toast('复制失败')
        })
    },
    clearPicker() {
      this.$refs.picker.setColumnIndex(0)
      this.show = false
    },
    // 设置
    setOptions() {
      this.set = true
    },
    // 设置背景颜色
    setBgColor(val) {
      this.config.bgColor = val.bgColor
      this.config.fzColor = val.fzColor
    },
    // 设置背景图
    setBgImg(val) {
      this.config.bgImg = val.img
    },
    // 计算
    calculation(type) {
      if (type == 'add') {
        if (this.config.fontSize < 30) {
          this.config.fontSize += 1
        }
      } else {
        if (this.config.fontSize > 16) {
          this.config.fontSize -= 1
        }
      }
    },
    // 设置行间距
    setLine(line) {
      this.config.lineHeight = line.val
    },
    // 播放到尾结束
    over(val) {
      // stateFlag == true 是开始播放， false是继续播放
      if (val == 'zanting') {
        this.stateFlag = false
      } else if (val == 'kaishi') {
        this.stateFlag = true
      }
      this.state = false
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .van-popup {
  border-radius: 0px;
}
.script {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  overflow: hidden;

  .script-top {
    width: 100vw;
    padding: 14px 40px;
    font-size: 24px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    color: #fe764b;
    .st-selectChart {
      float: left;
      border: 1px solid #fe764b;
      padding: 4px 14px;
      border-radius: 40px;
    }
    i {
      font-size: 26px;
    }
    .st-copy {
      float: right;
      line-height: 44px;
    }
  }
  .script-options-zhanwe {
    height: 146px;
  }
  .script-options {
    /*   position: fixed;
    bottom: 0px;
    left: 0px; */
    background: white;
    box-shadow: rgba(0, 0, 0, 0.1) 0px -2px 12px;
    width: 100%;
    padding: 20px 30px 10px;
    /*    padding-bottom: constant(safe-area-inset-bottom); ///兼容 IOS<11.2/
    padding-bottom: env(safe-area-inset-bottom); ///兼容 IOS>11.2/ */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .so-top {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 24px;
      margin-bottom: 12px;
    }
    .so-tips {
      color: #999999;
      text-align: left;
      font-size: 20px;
    }

    .options-btn {
      border-radius: 60px;
      white-space: nowrap;
      border: none;
      padding: 0px 20px;
      background: none;
      color: #fff;
      background-image: linear-gradient(
        to right,
        #ff9f71,
        #ff9567,
        #ff8b5d,
        #fe8154,
        #fe764b
      ) !important;
    }

    .options-slider {
      position: relative;
      margin: 0px 30px;
      flex: 1;
      color: #fff;
      font-weight: bold;
      font-size: 28px;
    }
    .options-btn-set {
      border-radius: 60px;
      white-space: nowrap;
      border: none;
      padding: 0px 20px;
      font-size: 30px;
      color: #fe784d;
    }
  }
  /deep/ .van-slider__bar {
    overflow: hidden;
    background-image: linear-gradient(
      to right,
      #ff9f71,
      #ff9567,
      #ff8b5d,
      #fe8154,
      #fe764b
    );
  }
  .custom-button {
    width: 68px;
    height: 68px;
    line-height: 68px;
    text-align: center;
    margin-right: 80px;
    border-top-right-radius: 100px;
    border-bottom-right-radius: 100px;
  }
}
/deep/ .setOverlay {
  background: rgba(255, 0, 0, 0) !important;
}
.setBox {
  padding: 20px 40px 0px;
  .setBoxRow {
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    .setBoxRow-label {
      width: 100px;
      text-align: left;
      font-size: 24px;
    }
    .setBoxRow-options {
      flex: 1;
      display: flex;
      align-items: center;
      .van-radio-group {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .setBoxRow-bg {
          width: 50px;
          height: 50px;
          border-radius: 50px;
        }
        .setBoxRow-border {
          border: 2px solid #fe784d;
          color: #fe784d;
        }
      }
      .sbr-spacing {
        color: #666;
        font-size: 30px;
        border-radius: 50px;
        padding: 8px 40px;
        border: 1px solid #dedede;
      }
      .sbr-spacing-turn {
        color: #666;
        font-size: 28px;
        border-radius: 50px;
        margin-left: 26px;
        padding: 8px 30px;
        border: 1px solid #dedede;
      }
      /deep/ .van-radio__icon {
        height: auto;
      }
    }
    .setBoxRow-font {
      color: #666;
      .sbr-font-btn {
        padding: 8px 68px;
        font-size: 30px;
        color: #666;
        border: 1px solid #dedede;
        border-radius: 25px;
      }
      .sbr-font-num {
        padding: 0px 20px;
        font-size: 28px;
      }
    }
  }
}
</style>
