<template>
  <div class="box">
    <!-- <div class="box" :style="{ backgroundImage: 'url(' + config.bgImg + ')' }"> -->
    <div class="content-bg" ref="parentBox">
      <div class="scipt-content" :style="{
          background: config.bgColor,
          fontSize: fontSize,
          color: config.fzColor,
          lineHeight: lineHeight
        }" ref="childBox" v-html="textArr"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['content', 'state', 'config'],
  data() {
    return {
      textArr: '',
      startFlag: true,
      interval: null,
      timeOut: null,
      stopFlag: true,
      n: 0,
      fontSize: 0,
      lineHeight: 0
    }
  },
  watch: {
    state(newVal) {
      if (newVal) {
        this.start()
      } else {
        this.stop()
      }
    },
    content: {
      handler() {
        this.setContentText()
      },
      immediate: true
    },
    'config.fontSize': {
      handler(newVal) {
        this.fontSize = (((newVal * 1.6) / 750) * 100).toFixed(4) + 'vw'
        this.lineHeight =
          (
            ((this.config.lineHeight * this.config.fontSize * 1.6) / 750) *
            100
          ).toFixed(4) + 'vw'
      },
      immediate: true
    },
    'config.lineHeight': {
      handler(newVal) {
        this.lineHeight =
          (((newVal * this.config.fontSize * 1.6) / 750) * 100).toFixed(4) +
          'vw'
      }
    }
  },
  beforeUnmount() {
    this.stop()
  },
  methods: {
    setContentText() {
      let textArr = this.content.split('\n\n')
      let text = ''
      textArr.forEach(element => {
        text += `<p>${element}</p>`
      })
      this.textArr = text
    },
    start() {
      let parent = this.$refs.parentBox
      let pH = parent.clientHeight
      let pS = parent.scrollTop
      let child = this.$refs.childBox
      let cH = child.clientHeight
      let speed = this.config.val / 60
      if (pS + pH >= cH - pH / 3) {
        parent.scrollTop = 0
        child.style.transform = 'translateY(0px)'
      }
      this.n = 0
      pS = parent.scrollTop
      this.timeOut = setTimeout(
        () => {
          let myScroll = () => {
            if (this.n < cH - pS - pH - 50) {
              this.n += speed
              this.$refs.childBox.style.transform = `translateY(-${this.n}px)`
              this.interval = window.requestAnimationFrame(myScroll)
            } else {
              window.cancelAnimationFrame(this.interval)
              setTimeout(() => {
                this.stop(true)
                this.stopFlag = true
                this.$emit('over', 'kaishi')
              }, 5000)
            }
          }
          this.interval = window.requestAnimationFrame(myScroll)
        },
        this.stopFlag ? 5000 : 0
      )
    },
    stop(flag = false) {
      // 暂停
      if (this.interval) {
        this.$refs.childBox.style.transform = 'translateY(0px)'
        this.$refs.parentBox.scrollTop = this.$refs.parentBox.scrollTop + this.n
      }
      if (this.interval && !flag) {
        this.stopFlag = false
      }
      clearTimeout(this.timeOut)
      window.cancelAnimationFrame(this.interval)
      this.timeOut = null
      this.interval = null
      this.startFlag = true
    },
    reset() {
      this.stop()
      this.$refs.childBox.style.transform = 'translateY(0px)'
      this.$refs.parentBox.scrollTop = 0
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ p {
  padding: 10px 10px 0px;
  text-align: justify;
}

.box {
  flex: 1;
  // margin: 20px 0px;
  box-sizing: border-box;
  // padding: 35px;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  .content-bg {
    overflow: auto;
    // min-height: 100vh;
    background: #f8f8f8;
    height: 100%;
    .scipt-content {
      background: white;
      text-align: left;
      font-size: 32px;
      min-height: 100vh;
      padding: 20px;
      padding-bottom: 60%;
    }
  }
}
</style>
