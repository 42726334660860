<template>
  <div
    class="content-bg"
    :style="{ background: config.bgColor, height: mg }"
    ref="parentBox"
    @touchstart.prevent="touch"
    @touchend.prevent="touchEnd"
    @touchmove.prevent="touchmove"
  >
    <div
      class="scipt-content"
      :style="{
        background: config.bgColor,
        fontSize: config.fontSize - 6 + 'px',
        color: config.fzColor,
        lineHeight: lineHeight + 'px'
      }"
      ref="childBox"
      v-html="textArr"
    ></div>
  </div>
</template>

<script>
export default {
  props: ['content', 'state', 'config'],
  data() {
    return {
      textArr: '',
      startFlag: true,
      timeOut: null,
      interval: null,
      stopFlag: true,
      n: 0,
      // 手指落点
      touchStart: 0,
      lineHeight: 0,
      pH: 0,
      mg: 0
    }
  },
  watch: {
    state(newVal) {
      if (newVal) {
        this.$nextTick(() => {
          this.lineHeight = Math.ceil(
            this.config.lineHeight * this.config.fontSize
          )
          // 算出之前滚动几次
          let beforeScroll = (this.n = Math.abs(this.n) / this.pH)
          this.mg =
            document.body.clientHeight -
            (document.body.clientHeight % this.lineHeight) +
            'px'
          this.pH = this.$refs.parentBox.clientHeight - this.lineHeight
          this.n = -beforeScroll * this.pH
          this.$refs.childBox.style.transform = `translateY(${this.n}px)`
        })
        this.start()
      } else {
        this.stop()
      }
    },
    content: {
      handler() {
        this.setContentText()
      },
      immediate: true // 立即执行
    },
    'config.lineHeight': {
      handler(val) {
        this.lineHeight = Math.ceil(
          this.config.lineHeight * this.config.fontSize
        )
        this.mg =
          document.body.clientHeight -
          (document.body.clientHeight % this.lineHeight) +
          'px'
        this.$nextTick(() => {
          this.pH = this.$refs.parentBox.clientHeight - this.lineHeight
        })
      },
      deep: true,
      immediate: true
    }
  },
  beforeUnmount() {
    clearInterval(this.interval)
    clearInterval(this.timeOut)
  },
  methods: {
    // 滚动
    touch(event) {
      this.touchStart = event.changedTouches[0].pageY
      this.stop()
    },
    touchEnd(event) {
      let touchEnd = event.changedTouches[0].pageY
      // 滚动距离 负数下拉，正数上拉
      let scrollNum = this.touchStart - touchEnd
      if (scrollNum > 80) {
        if (
          Math.abs(this.n - this.pH) / this.pH <=
          Math.ceil(this.$refs.childBox.clientHeight / this.pH) - 1
        ) {
          this.n -= this.pH
          this.$refs.childBox.style.transform = `translateY(${this.n}px)`
        }
      } else {
        this.$refs.childBox.style.transform = `translateY(${this.n}px)`
      }
      if (scrollNum < -80 && this.n < 0) {
        this.n += this.pH
        this.$refs.childBox.style.transform = `translateY(${this.n}px)`
      } else {
        this.$refs.childBox.style.transform = `translateY(${this.n}px)`
      }
    },
    touchmove(event) {
      let current = event.changedTouches[0].pageY
      let scrollNum = this.touchStart - current
      if (Math.abs(scrollNum) < 140 && this.n <= 0) {
        if (scrollNum > 0) {
          this.$refs.childBox.style.transform = `translateY(${
            this.n - scrollNum
          }px)`
        } else {
          this.$refs.childBox.style.transform = `translateY(${
            this.n - scrollNum
          }px)`
        }
      }
    },
    setContentText() {
      let textArr = this.content.split('\n\n')
      let text = ''
      textArr.forEach(element => {
        text += `<p>${element}</p>`
      })
      this.textArr = text
    },
    start() {
      // 检测是否到最后了
      if (
        Math.abs(this.n - this.pH) / this.pH >
        Math.ceil(this.$refs.childBox.clientHeight / this.pH) - 1
      ) {
        this.n = 0
        this.$refs.childBox.style.transform = `translateY(${this.n}px)`
      }
      let time = (150 - this.config.val) * 100
      this.timeOut = setTimeout(
        () => {
          this.intervalScroll()
          this.interval = setInterval(this.intervalScroll, time)
        },
        this.stopFlag ? 5000 : 0
      )
    },
    stop(flag = false) {
      // 如果有计时器和flag为true就是结束
      if (this.timeOut && flag == true) {
        this.$emit('over', 'kaishi')
      } else if (this.timeOut) {
        this.stopFlag = false
        this.$emit('over', 'zanting')
      }
      clearTimeout(this.timeOut)
      clearInterval(this.interval)
      this.interval = null
      this.timeOut = null
    },
    // 定时滚动
    intervalScroll() {
      if (
        Math.abs(this.n - this.pH) / this.pH <=
        Math.ceil(this.$refs.childBox.clientHeight / this.pH) - 1
      ) {
        this.n -= this.pH
        this.$refs.childBox.style.transform = `translateY(${this.n}px)`
      } else {
        this.stop(true)
      }
    },
    reset() {
      this.stop()
      this.touchStart = 0
      this.n = 0
      this.$refs.childBox.style.transform = 'translateY(0px)'
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ p {
  letter-spacing: 2px;
  vertical-align: bottom;
  box-sizing: border-box;
  // font-size: 0px;
}

.content-bg {
  margin: auto;
  // flex: 1;
  height: 100%;
  // overflow: auto;
  // min-height: 100vh;
  overflow: hidden;
  background: #f8f8f8;
  padding: 0px 30px;
  .scipt-content {
    background: white;
    transition: transform 0.2s ease-out;
    text-align: left;
    font-size: 0px;
    min-height: 100vh;
    padding: 0px 20px;
    padding-bottom: 30%;
  }
}
</style>
